
<div class="form-container">
    <button class="back-button" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <h2>Update Salary History</h2>
    <form [formGroup]="salaryHistoryForm">
      <div class="form-row">
        <div class="form-group col-sm-12 col-md-12 col-lg-12">
          <label for="employeeType">Employee Type<span class="requried-field">*</span></label>

          <select
                    id="employeeType"
                    name="employeeType"
                    class="form-control"
                    formControlName="employeeType"
                    [ngClass]="{ 'is-invalid': submitted && f['employeeType'].errors }"
                  >
                  <option value="" selected disabled>--Please select--</option>
                    <option
                      *ngFor="let data of labourData"
                      [value]="data.employeeType"
                      >{{ data.employeeType}}
                    </option>
                  </select>
          <div *ngIf="submitted && f['employeeType'].errors" class="invalid-feedback">
            <div *ngIf="f['employeeType'].errors['required']">Labour Type is required</div>
          </div>
        </div>
      
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="employeeName">Employee Name<span class="requried-field">*</span></label>
            <input
              type="text"
              id="employeeName"
              placeholder="Enter employeeName"
              class="form-control"
              formControlName="employeeName"
              [ngClass]="{ 'is-invalid': submitted && f['employeeName'].errors }"
            />
            <div *ngIf="submitted && f['employeeName'].errors" class="invalid-feedback">
              <div *ngIf="f['employeeName'].errors['required']">Employee Name is required</div>
            </div>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="salaryMonth">Salary Month<span class="requried-field">*</span></label>
            <input
              type="date"
              id="salaryMonth"
              placeholder="Enter salaryMonth"
              class="form-control"
              formControlName="salaryMonth"
              [ngClass]="{ 'is-invalid': submitted && f['salaryMonth'].errors }"
            />
            <div *ngIf="submitted && f['salaryMonth'].errors" class="invalid-feedback">
              <div *ngIf="f['salaryMonth'].errors['required']">SalaryMonth is required</div>
            </div>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="salaryYear">Salary Year<span class="requried-field">*</span></label>
            <input
              type="date"
              id="salaryYear"
              placeholder="Enter salaryYear"
              class="form-control"
              formControlName="salaryYear"
              [ngClass]="{ 'is-invalid': submitted && f['salaryYear'].errors }"
            />
            <div *ngIf="submitted && f['salaryYear'].errors" class="invalid-feedback">
              <div *ngIf="f['salaryYear'].errors['required']">SalaryYear is required</div>
            </div>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="basicpay">Basic Pay<span class="requried-field">*</span></label>
            <input
              type="number"
              id="basicPay"
              placeholder="Enter basicPay"
              class="form-control"
              formControlName="basicPay"
              [ngClass]="{ 'is-invalid': submitted && f['basicPay'].errors }"
            />
            <div *ngIf="submitted && f['basicPay'].errors" class="invalid-feedback">
              <div *ngIf="f['basicPay'].errors['required']">Basic Pay is required</div>
            </div>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="allowance">Allowance<span class="requried-field">*</span></label>
            <input
              type="text"
              id="allowance"
              placeholder="Enter allowance"
              class="form-control"
              formControlName="allowance"
              [ngClass]="{ 'is-invalid': submitted && f['allowance'].errors }"
            />
            <div *ngIf="submitted && f['allowance'].errors" class="invalid-feedback">
              <div *ngIf="f['allowance'].errors['required']">Allowance is required</div>
            </div>
        </div>
        <div class="form-group col-sm-12 col-md-12 col-lg-6">
          <label for="grossSalary">Gross Salary<span class="requried-field">*</span></label>
          <input
            type="text"
            id="grossSalary"
            placeholder="Enter grossSalary"
            class="form-control"
            formControlName="grossSalary"
            [ngClass]="{ 'is-invalid': submitted && f['grossSalary'].errors }"
          />
          <div *ngIf="submitted && f['grossSalary'].errors" class="invalid-feedback">
            <div *ngIf="f['grossSalary'].errors['required']">Gross Salary is required</div>
          </div>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="noofdays">No Of Days<span class="requried-field">*</span></label>
            <input
              type="text"
              id="noofdays"
              placeholder="Enter noofdays"
              class="form-control"
              formControlName="noofdays"
              [ngClass]="{ 'is-invalid': submitted && f['noofdays'].errors }"
            />
            <div *ngIf="submitted && f['noofdays'].errors" class="invalid-feedback">
              <div *ngIf="f['noofdays'].errors['required']">No Of Days is required</div>
            </div>
        </div>
        <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="perdaysSalary">Per Day Salary<span class="requried-field">*</span></label>
            <input
              type="text"
              id="perdaysSalary"
              placeholder="Enter perdaysSalary"
              class="form-control"
              formControlName="perdaysSalary"
              [ngClass]="{ 'is-invalid': submitted && f['perdaysSalary'].errors }"
            />
            <div *ngIf="submitted && f['perdaysSalary'].errors" class="invalid-feedback">
              <div *ngIf="f['perdaysSalary'].errors['required']">perdaysSalary is required</div>
            </div>
        </div>
        <div class="form-group col-sm-12 col-md-12 col-lg-6">
            <label for="lopDays">LOP Days<span class="requried-field">*</span></label>
            <input
              type="text"
              id="lopDays"
              placeholder="Enter lopDays"
              class="form-control"
              formControlName="lopDays"
              [ngClass]="{ 'is-invalid': submitted && f['lopDays'].errors }"
            />
            <div *ngIf="submitted && f['lopDays'].errors" class="invalid-feedback">
              <div *ngIf="f['lopDays'].errors['required']">LOP Days is required</div>
            </div>
          </div>
     
      <div class="form-group col-sm-12 col-md-12 col-lg-6">
        <label for="lopAmount">LOP Amount<span class="requried-field">*</span></label>
        <input
          type="text"
          id="lopAmount"
          placeholder="Enter lopAmount"
          class="form-control"
          formControlName="lopAmount"
          [ngClass]="{ 'is-invalid': submitted && f['lopAmount'].errors }"
        />
        <div *ngIf="submitted && f['lopAmount'].errors" class="invalid-feedback">
          <div *ngIf="f['lopAmount'].errors['required']">LOP Amount is required</div>
        </div>
        </div>
        <div class="form-group col-sm-12 col-md-12 col-lg-6">
            <label for="netSalary">Net Salary<span class="requried-field">*</span></label>
            <input
              type="text"
              id="netSalary"
              placeholder="Enter netSalary"
              class="form-control"
              formControlName="netSalary"
              [ngClass]="{ 'is-invalid': submitted && f['netSalary'].errors }"
            />
            <div *ngIf="submitted && f['netSalary'].errors" class="invalid-feedback">
              <div *ngIf="f['netSalary'].errors['required']">Net Salary is required</div>
            </div>
          </div>
          <div class="form-group col-sm-12 col-md-12 col-lg-6">
            <label for="otherAddiction">Other Addiction<span class="requried-field">*</span></label>
            <input
              type="text"
              id="otherAddiction"
              placeholder="Enter otherAddiction"
              class="form-control"
              formControlName="otherAddiction"
              [ngClass]="{ 'is-invalid': submitted && f['otherAddiction'].errors }"
            />
            <div *ngIf="submitted && f['otherAddiction'].errors" class="invalid-feedback">
              <div *ngIf="f['otherAddiction'].errors['required']">Other Addiction is required</div>
            </div>
          </div>
          <div class="form-group col-sm-12 col-md-12 col-lg-6">
            <label for="totalLoanBalance">Total Loan balance<span class="requried-field">*</span></label>
            <input
              type="text"
              id="totalLoanBalance"
              placeholder="Enter totalLoanBalance"
              class="form-control"
              formControlName="totalLoanBalance"
              [ngClass]="{ 'is-invalid': submitted && f['totalLoanBalance'].errors }"
            />
            <div *ngIf="submitted && f['totalLoanBalance'].errors" class="invalid-feedback">
              <div *ngIf="f['totalLoanBalance'].errors['required']">Total loan balance is required</div>
            </div>
          </div>
          <div class="form-group col-sm-12 col-md-12 col-lg-6">
            <label for="loanbalanceDeduct">Loan Balance Debut<span class="requried-field">*</span></label>
            <input
              type="text"
              id="loanbalanceDeduct"
              placeholder="Enter loanbalanceDeduct"
              class="form-control"
              formControlName="loanbalanceDeduct"
              [ngClass]="{ 'is-invalid': submitted && f['loanbalanceDeduct'].errors }"
            />
            <div *ngIf="submitted && f['loanbalanceDeduct'].errors" class="invalid-feedback">
              <div *ngIf="f['loanbalanceDeduct'].errors['required']">Debut Balance is required</div>
            </div>
          </div>
          <div class="form-group col-sm-12 col-md-12 col-lg-6">
            <label for="toBePaid">To Be Paid<span class="requried-field">*</span></label>
            <input
              type="text"
              id="toBePaid"
              placeholder="Enter toBePaid"
              class="form-control"
              formControlName="toBePaid"
              [ngClass]="{ 'is-invalid': submitted && f['toBePaid'].errors }"
            />
            <div *ngIf="submitted && f['toBePaid'].errors" class="invalid-feedback">
              <div *ngIf="f['toBePaid'].errors['required']">To Be Paid is required</div>
            </div>
          </div>
          <div class="form-group col-sm-12 col-md-12 col-lg-6">
            <label for="description">Description<span class="requried-field">*</span></label>
            <input
              type="text"
              id="description"
              placeholder="Enter description"
              class="form-control"
              formControlName="description"
              [ngClass]="{ 'is-invalid': submitted && f['description'].errors }"
            />
            <div *ngIf="submitted && f['description'].errors" class="invalid-feedback">
              <div *ngIf="f['description'].errors['required']">Account No is required</div>
            </div>
          </div>
          <div class="form-group col-sm-12 col-md-12 col-lg-12">
            <label for="pfEligibility">PF Eligibility<span class="requried-field">*</span></label>
            <select
                      id="pfEligibility"
                      name="pfEligibility"
                      class="form-control"
                      formControlName="pfEligibility"
                      [ngClass]="{ 'is-invalid': submitted && f['employeeType'].errors }"
                    >
                    <option value="" selected disabled>--Please select--</option>
                      <option
                        *ngFor="let data of salaryData"
                        [value]="data.pfEligibility"
                        >{{ data.pfEligibility}}
                      </option>
                    </select>
            <div *ngIf="submitted && f['pfEligibility'].errors" class="invalid-feedback">
              <div *ngIf="f['pfEligibility'].errors['required']">PF Eligibility is required</div>
            </div>
          </div>
          <div class="form-group col-sm-12 col-md-12 col-lg-12">
            <label for="pfEmployerper">PF Employer<span class="requried-field">*</span></label>
  
            <select
                      id="pfEmployerper"
                      name="pfEmployerper"
                      class="form-control"
                      formControlName="pfEmployerper"
                      [ngClass]="{ 'is-invalid': submitted && f['pfEmployerper'].errors }"
                    >
                    <option value="" selected disabled>--Please select--</option>
                      <option
                        *ngFor="let data of labourData"
                        [value]="data"
                        >{{ data}}
                      </option>
                    </select>
            <div *ngIf="submitted && f['pfEmployerper'].errors" class="invalid-feedback">
              <div *ngIf="f['pfEmployerper'].errors['required']">PF Employer is required</div>
            </div>
          </div>
          <div class="form-group col-sm-12 col-md-12 col-lg-12">
            <label for="pfemployeectb">Pf Employee<span class="requried-field">*</span></label>
            <select
                      id="pfEmployeectb"
                      name="pfEmployeectb"
                      class="form-control"
                      formControlName="pfEmployeectb"
                      [ngClass]="{ 'is-invalid': submitted && f['pfEmployeectb'].errors }"
                    >
                    <option value="" selected disabled>--Please select--</option>
                      <option
                        *ngFor="let data of salaryData"
                        [value]="data"
                        >{{ data}}
                      </option>
                    </select>
            <div *ngIf="submitted && f['pfEmployeectb'].errors" class="invalid-feedback">
              <div *ngIf="f['pfEmployeectb'].errors['required']">PF Employee is required</div>
            </div>
          </div>
          <div class="form-group col-sm-12 col-md-12 col-lg-12">
            <label for="esiEligibility">ESI Eligibility<span class="requried-field">*</span></label>
            <select
                      id="esiEligibility"
                      name="esiEligibility"
                      class="form-control"
                      formControlName="esiEligibility"
                      [ngClass]="{ 'is-invalid': submitted && f['esiEligibility'].errors }"
                    >
                    <option value="" selected disabled>--Please select--</option>
                      <option
                        *ngFor="let data of salaryData"
                        [value]="data"
                        >{{ data}}
                      </option>
                    </select>
            <div *ngIf="submitted && f['esiEligibility'].errors" class="invalid-feedback">
              <div *ngIf="f['esiEligibility'].errors['required']">Labour Type is required</div>
            </div>
          </div>
          <div class="form-group col-sm-12 col-md-12 col-lg-12">
            <label for="esiEmployerper">ESI Employer<span class="requried-field">*</span></label>
  
            <select
                      id="esiEmployerper"
                      name="esiEmployerper"
                      class="form-control"
                      formControlName="esiEmployerper"
                      [ngClass]="{ 'is-invalid': submitted && f['esiEmployerper'].errors }"
                    >
                    <option value="" selected disabled>--Please select--</option>
                      <option
                        *ngFor="let data of salaryData"
                        [value]="data"
                        >{{ data}}
                      </option>
                    </select>
            <div *ngIf="submitted && f['esiEmployerper'].errors" class="invalid-feedback">
              <div *ngIf="f['esiEmployerper'].errors['required']">ESI Employer is required</div>
            </div>
          </div>
          <div class="form-group col-sm-12 col-md-12 col-lg-12">
            <label for="esiEmployeectb">ESI Employee<span class="requried-field">*</span></label>
  
            <select
                      id="esiEmployeectb"
                      name="esiEmployeectb"
                      class="form-control"
                      formControlName="esiEmployeectb"
                      [ngClass]="{ 'is-invalid': submitted && f['esiEmployeectb'].errors }"
                    >
                    <option value="" selected disabled>--Please select--</option>
                      <option
                        *ngFor="let data of salaryData"
                        [value]="data"
                        >{{ data}}
                      </option>
                    </select>
            <div *ngIf="submitted && f['esiEmployeectb'].errors" class="invalid-feedback">
              <div *ngIf="f['esiEmployeectb'].errors['required']">ESI Employee is required</div>
            </div>
          </div>
      </div>
    <!-- </div> -->

      
  
      <div class="formbutton">

     
        <button type="submit" class="btn btn-primary" (click)="onSubmit(salaryHistoryForm.value)">Submit</button>
        <button type="submit" class="btn btn-danger btn2"  (click)="onCancel()">Cancel</button>
      </div>
    </form>
  </div>
  