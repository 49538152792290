import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { NotificationService } from '../../../services/notificationService';
import { DataService } from '../data.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrl: './edit.component.scss'
})
export class EditComponent {
  labourData: string[] = ['Contract','Wages',];
  states: string[] = ['State 1', 'State 2', 'State 3'];
  keyword1="projectName";
  siteNoData:any[]=[];
  siteData:any[]=[];
  getSite:any[]=[];
  projectName:any[]=[];
  salaryHistoryForm: FormGroup = new FormGroup({});
  submitted: boolean = false;
  salaryConfig:boolean = false;
  salaryConfigData:any[]=[];
  salaryData:any[]=[];
  id:any;

  constructor(private data: DataService,
    private formBuilder: FormBuilder,
    private toastr:NotificationService,
    private router:Router,
    private route:ActivatedRoute
  ) {}

  ngOnInit()  {
    if (this.route.snapshot.queryParamMap.get("id")) {
      // @ts-ignore TS2322
      this.id = this.route.snapshot.queryParamMap.get("id");
      this.getById(this.id);
    }

    this.salaryHistoryForm= this.formBuilder.group({
      employeeType: ['', Validators.required],
      employeeId: ['', Validators.required],
      grossSalary: ['',Validators.required],
      basicPay_per: ['',],
      basicPay:  ['',Validators.required],
      allowances: ['', Validators.required],
      salaryYear: ['',Validators.required],
      salaryMonth: ['', Validators.required],
      noofdays:['',Validators.required],
      perdaysSalary:['',Validators.required],
      lopDays:['',Validators.required],
      lopAmount:['',Validators.required],
      pfEligibility:['',Validators.required],
      pfEmployerPer:['',Validators.required],
      pfEmployeectb:['',Validators.required],
      esiEligibility:['',Validators.required],
      esiEmployerPer:['',Validators.required],
      esiEmployeectb:['',Validators.required],
      otherAddiction:['',Validators.required],
      netSalary:['',Validators.required],
      totalLoanBalance:['',Validators.required],
      loanbalanceDeduct:['',Validators.required],
      toBePaid:['',Validators.required],
      description:['',Validators.required],

     
      
    });
    this.data.getSalaryConfig().subscribe((val:any)=>{
      
        if (val) {
          this.salaryConfigData = Object.keys(val).map(key => ({ id: key, ...val[key] }));
          console.log(this.salaryConfigData)
          this.salaryConfigData.forEach((res:any)=>{
            this.salaryData.push(res)
          })
        }
    })
 
  }
  getById(_id:any){
    this.data.getById(_id).subscribe((res:any)=>{
      console.log(res,"res")
     if(res){
      //console.log(this.categoryData,"concel")
      this.updateView(res)
     }
    })
    
  }
  updateView(data:any){
    console.log(data,"Edit")
    this.salaryHistoryForm.patchValue({
      employeeType: data.employeeType,
      employeeId: data.employeeId,
      grossSalary: data.grossSalary,
      basicPay_per: data.basicPay_per,
      basicPay:  data.basicPay,
      allowances: data.allowances,
      salaryYear: data.salaryYear,
      salaryMonth: data.salaryMonth,
      noofdays:data.noofdays,
      perdaysSalary:data.perdaysSalary,
      lopDays:data.lopDays,
      lopAmount:data.lopAmount,
      pfEligibility:data.pfEligibility,
      pfEmployerPer:data.pfEmployerPer,
      pfEmployeectb:data.pfEmployeectb,
      esiEligibility:data.esiEligibility,
      esiEmployerPer:data.esiEmployerPer,
      esiEmployeectb:data.esiEmployeectb,
      otherAddiction:data.otherAddiction,
      netSalary:data.netSalary,
      totalLoanBalance:data.totalLoanBalance,
      loanbalanceDeduct:data.loanbalanceDeduct,
      toBePaid:data.toBePaid,
      description:data.description,
    })
  }

  goBack() {
    this.router.navigate(['/dashboard/salaryHistory']);
  }
 
  get f() {
    return this.salaryHistoryForm.controls;
  }

  onSubmit(data: any) {
    if (this.salaryHistoryForm.invalid) {
      this.toastr.showError("Error", "");
      return;
    }

    data.updateOn=moment().format()
    

    this.data.update(data,this.id).subscribe((res:any)=>{
      console.log('Project Created!',res);
      this.toastr.showSuccess("Successfully!!","Project Added")
      this.router.navigate(['/dashboard/salaryHistory'])
    })
  }
  onCancel(){
    this.salaryHistoryForm.reset()
  }
}
