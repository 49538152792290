import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CollapseModule } from "ngx-bootstrap/collapse";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
//import { ToastrModule } from 'ngx-toastr';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatCardModule} from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import {MatPaginatorModule} from '@angular/material/paginator';
import { MatTableModule} from '@angular/material/table';
import { NotificationService } from '../../services/notificationService';
import { NgxPaginationModule } from 'ngx-pagination';
import { AddComponent } from './add/add.component';
import { EditComponent } from './edit/edit.component';
import { salaryHistoryRoutingModule } from './salaryHistory.routing';
import { SalaryHistoryComponent } from './salary-history.component';


@NgModule({
    declarations: [
    SalaryHistoryComponent,
     AddComponent,
     EditComponent
    ],
    providers: [NotificationService],
    imports: [

       
        CommonModule,
        BsDatepickerModule.forRoot(),
        ModalModule.forRoot(),
        BsDropdownModule.forRoot(),
        CollapseModule.forRoot(),
        FormsModule,
        NgxPaginationModule,
        ReactiveFormsModule,
       // ToastrModule.forRoot(),
        MatListModule,
        MatIconModule,
        MatTableModule,
        MatCardModule,
       // MatPaginator,
        MatPaginatorModule,
        //MatTableDataSource,
        MatProgressBarModule,
        MatGridListModule,
       salaryHistoryRoutingModule,
        
        
       
        
    ]
})
export class SalaryHistoryModule{ }
